export class URLUtils {
  private static readonly urlQuerySeparator = /\?(.*)/;
  private static readonly splitLimit = 2;

  /**
   * Retrieves the value of a specified query parameter from a given URL.
   *
   * @param url - The URL string from which to extract the query parameter value.
   * @param paramName - The name of the query parameter whose value is to be retrieved.
   * @returns The value of the specified query parameter, or `undefined` if the parameter is not found.
   */
  public static getQueryParamValue(url: string, paramName: string): string | undefined {
    const query = url.split(URLUtils.urlQuerySeparator, URLUtils.splitLimit)[1];

    return new URLSearchParams(query).get(paramName) ?? undefined;
  }
}
